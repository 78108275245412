@import '../variables';

.loan-detail {
    .header-container {
        display: flex;
    }

    .title {
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 12px !important;
    }

    .edit-button {
        padding: 5px 33px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        background: $button-primary-color;
        box-shadow: 0 2px 0 rgba(0, 0, 0, 0.043);
        color: $button-secondary-color;
    }

    .display-button {
        background: #66b828;
        font-family: 'Noto Sans JP';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #ffffff;
        padding: 5px 13px 3px 13px;
        border-radius: 2px;
    }

    .text-updated-at {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #999999;
    }

    .block-date > div,
    .block-status > div {
        align-self: center;
    }

    .block-status {
        margin-bottom: 12px;

        div:first-child {
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 17px;

            color: #000000;
        }

        div:nth-child(2) {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 17px;
            color: #000000;
        }

        button {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
        }
    }

    .block-sub-title {
        margin-bottom: 12px;

        .sub-title {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            color: #000000;
            padding: 2.5px 0 2.5px 16px;
            border-left: 4px solid #006bb5;
        }
    }

    .block-sub-title-second {
        margin-top: 28px;
    }

    .block-form {
        padding-bottom: 141px;

        .ant-form-item {
            margin-bottom: 16px;
        }

        .ant-form-item-label > label {
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 17px;
            color: #000000;

            &::after {
                display: none;
            }
        }

        .has-two-input {
            margin-left: 7px;
        }

        .ant-checkbox-wrapper + .ant-checkbox-wrapper {
            margin-left: 0;
        }

        .checkbox-item {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
        }
    }
}

.loan-edit {
    .block-date {
        margin-bottom: 17px;
    }

    .has-two-inputs {
        @media all and (min-width: 768px) {
            padding-right: 0 !important;
        }
    }

    .status-radio {
        display: flex;

        .ant-radio-group {
            margin-left: auto;
        }

        .status-radio-first {
            padding-right: 100px;
            white-space: nowrap;
        }
    }

    @media screen and (max-width: 767px) {
        .status-radio {
            display: block;
        }
    }

    button {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 5px;
    }

    .cancel-button {
        background: #ffffff;
        border: 1px solid #d9d9d9;
        box-sizing: border-box;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
        border-radius: 2px;
        color: rgba(0, 0, 0, 0.85);
        padding: 5px 13px;
        margin-right: 8px;
    }

    .save-button {
        background: #ffffff;
        border: 1px solid #006bb5;
        box-sizing: border-box;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
        border-radius: 2px;
        color: #006bb5;
        padding: 5px 13px;
        margin-right: 8px;
    }

    .confirm-button {
        background: #006bb5;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
        border-radius: 2px;
        color: #ffffff;
        padding: 5px 34px;
    }
}
