@import '../variables';

.profiles-management {
    background-color: $background-white;

    .header-container {
        display: flex;
        margin-left: 2px;
        margin-top: 17px;
        padding-right: 18px;

        .title {
            color: #444444;
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 30px;
            margin-bottom: 23px !important;
        }

        .action-buttons {
            margin-left: auto;
            margin-top: 5px;

            button {
                background: #006bb5;
                box-shadow: 0 2px 0 rgba(0, 0, 0, 0.043);
                border-radius: 2px;
                padding: 5px 47px;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 22px;
                color: $background-white;
            }
        }
    }

    .profiles-container {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        overflow: auto hidden;
        margin-left: 9px;

        .ant-form-item {
            display: flex;
            flex-direction: column;
            margin-bottom: 13px;
        }

        .ant-form-item-label {
            color: #000000;
            text-align: left;
        }

        label::after {
            display: none;
        }

        input {
            background: $background-white;
            width: 400px;
            border: 1px solid #d9d9d9;
            box-sizing: border-box;
            border-radius: 2px !important;
            color: #444444;
        }

        input:read-only {
            background: #f5f5f5;
        }

        a,
        .btn-change-password {
            margin-top: 24px;
            color: #006bb5;
            text-decoration-line: underline;
            font-weight: bold;
            cursor: pointer;
            span {
                text-decoration: underline;
            }
        }
    }

    .footer-buttons {
        margin-top: 57px;
        display: flex;
        justify-content: center;

        button {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
            border-radius: 2px !important;
        }

        .cancel-button {
            padding: 5px 53px;
            background: $background-white;
            border: 1px solid #d9d9d9;
            margin-right: 9px;
            color: rgba(0, 0, 0, 0.85);
        }

        .save-button {
            background: #006bb5;
            padding: 5px 74px;
            color: $background-white;
        }
    }
}

.change-password-modal {
    .title {
        margin-bottom: 11px !important;
        font-weight: 700;
        line-height: 24px;
    }

    .required-mark {
        color: #656565;
        font-weight: 400;
        line-height: 22px;
    }

    .ant-form {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
    }

    .ant-btn-primary {
        background: #006bb5;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
        color: $background-white;
    }
}

.change-password-success {
    .title {
        font-size: 18px !important;
    }

    .ant-btn-primary {
        background: #006bb5;
        box-shadow: 0 2px 0 rgba(0, 0, 0, 0.043);
        color: $background-white;
    }
}
