.notification-content-management {
    height: 100%;
    display: flex;
    flex-direction: column;

    .header-container {
        margin-top: 30px;
        display: flex;

        .title {
            color: #444444;
            font-style: normal;
            font-weight: 700 !important;
            font-size: 20px !important;
            line-height: 30px;
        }

        .button-style {
            color: #000000d9;
            font-style: normal;
            font-weight: 400 !important;
            font-size: 14px !important;
            line-height: 22px;
            border: 1px solid #d9d9d9;
        }

        .button-left {
            margin: 0 2px 0 35px;
            background: #006bb5;
            color: white;
            border: 1px solid #006bb5;
        }

        .action-buttons {
            margin-left: auto;
            margin-right: 4px;

            button {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                color: #ffffff;
                background: #006bb5;
                padding: 5px 39px;
                box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
                border: 1px solid #006bb5;
            }
        }
    }

    .custom-table {
        flex-grow: 1;

        .action-button {
            float: none;
            justify-content: space-between;
            display: flex;
            align-items: center;

            .ant-btn {
                min-width: 100px;

                &:not(:last-child) {
                    margin-right: 6px;
                }
            }

            .detail-button {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                color: rgba(0, 0, 0, 0.85);
                background: #ffffff;
                border: 1px solid #d9d9d9;
                box-sizing: border-box;
                box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
                padding: 5px 35px;
            }

            .delete-button {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                color: #ff4d4f;
                background: #ffffff;
                border: 1px solid #ff4d4f;
                box-sizing: border-box;
                box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
                padding: 5px 35px;
            }
        }

        .ant-table-cell {
            height: 56px;
        }

        .draft-noti {
            position: absolute;
            right: 0;
            top: 0;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 22px;
            color: #444444;
        }

        .ant-table-thead > tr > th {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.85);
        }

        .ant-table-tbody > tr > td {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.85);
        }

        .ant-table-wrapper {
            flex-grow: 1;
        }

        .ant-table {
            flex-grow: 1;
        }

        .ant-pagination {
            justify-content: center !important;
        }

        .ant-spin-nested-loading {
            height: 100% !important;
        }

        .ant-spin-container {
            height: 100% !important;
            display: flex;
            flex-direction: column;
        }
    }
}

.category-management {
    height: 100%;
    display: flex;
    flex-direction: column;

    .header-container {
        margin-top: 30px;
        display: flex;

        .title {
            color: #444444;
            font-style: normal;
            font-weight: 700 !important;
            font-size: 20px !important;
            line-height: 30px;
        }

        .button-style {
            background-color: #006bb5;
            color: white;
            font-style: normal;
            font-weight: 400 !important;
            font-size: 14px !important;
            line-height: 22px;
            border: 1px solid #006bb5;
        }

        .button-left {
            margin: 0 2px 0 35px;
            background: white;
            color: #000000d9;
            border: 1px solid #d9d9d9;
        }

        .action-buttons {
            margin-left: auto;
            margin-right: 8px;

            button {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                color: #ffffff;
                background-color: #006bb5;
                padding: 5px 39px;
                box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
                border: 1px solid #006bb5;
            }
        }
    }

    .custom-table {
        flex-grow: 1;

        .action-button {
            float: none;
            justify-content: space-between;
            display: flex;
            align-items: center;

            .ant-btn {
                min-width: 100px;

                &:not(:last-child) {
                    margin-right: 6px;
                }
            }

            .detail-button {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                color: #ffffff;
                background: #006bb5;
                border: 1px solid #006bb5;
                box-sizing: border-box;
                box-shadow: 0 2px 0 rgba(0, 0, 0, 0.016);
                padding: 5px 35px;
            }

            .delete-button {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                color: #ff4d4f;
                background: #ffffff;
                border: 1px solid #ff4d4f;
                box-sizing: border-box;
                box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
                padding: 5px 35px;
            }
        }

        .ant-table-cell {
            height: 56px;
        }

        .ant-table-thead > tr > th {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.85);
        }

        .ant-table-tbody > tr > td {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.85);
        }

        .ant-table-wrapper {
            flex-grow: 1;
        }

        .ant-table {
            flex-grow: 1;
        }

        .ant-pagination {
            justify-content: center !important;
        }

        .ant-spin-nested-loading {
            height: 100% !important;
        }

        .ant-spin-container {
            height: 100% !important;
            display: flex;
            flex-direction: column;
        }
    }
}

@media all and (max-width: 570px) {
    .category-management,
    .notification-content-management {
        .header-container {
            margin-bottom: 10px;
            margin-top: 15px;
            display: grid;

            .button-left {
                margin: 0 0 10px 0;
            }

            .button-style {
                margin: 0 0 10px 0;
            }

            .action-buttons {
                margin: auto;
            }
        }

        .custom-table {
            .action-button {
                .view-btn {
                    padding-left: 30%;
                }
            }
        }
    }
}
