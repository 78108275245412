.base-layout-container {
    .background-white {
        background-color: #fff !important;
    }

    .font-size-12 {
        font-size: 12px;
    }

    .font-size-13 {
        font-size: 13px;
    }
}

.success-modal,
.custom-confirmation-modal,
.confirm-modal {
    &.registration-success {
        .ant-modal-body {
            padding-bottom: 52px;

            img {
                margin-top: 17px;
                margin-bottom: 16px;
            }

            .message {
                margin: 0;
            }
        }
    }

    &.send-mail-success {
        .ant-modal-body {
            padding-bottom: 125px;

            .title {
                margin-bottom: 0 !important;
            }

            img {
                margin-top: 17px;
                margin-bottom: 16px;
            }

            .message {
                margin: 0 !important;
            }
        }
    }

    &.modal-notification-error,
    &.modal-notification-success {
        .ant-modal-content {
            .ant-modal-body {
                .title {
                    margin-bottom: 0 !important;
                }

                img {
                    margin-top: 15px;
                    margin-bottom: 24px;
                }

                .message {
                    margin: 0 0 2rem 0 !important;
                }
            }
        }
    }

    &.confirm-delete-modal {
        height: 394px;

        .ant-modal-content {
            padding: 0;
        }

        .title {
            color: rgba(0, 0, 0, 0.85);
        }

        &.confirm-delete-modal {
            .title {
                margin-bottom: 23px !important;
            }

            .ant-modal-body {
                .message {
                    color: #000000;
                    margin-bottom: 29px !important;
                }
            }
        }

        &.delete-failed-modal {
            .title {
                margin-bottom: 18px !important;
            }

            .ant-modal-body {
                padding-bottom: 65px;

                .message {
                    margin-top: 20px;
                    margin-bottom: 44px;
                    color: #000000;
                }
            }
        }

        .ant-modal-body {
            padding-bottom: 41px;

            .message {
                margin-top: 20px;
                margin-bottom: 48px;
                font-size: 13px;
            }

            .form-buttons {
                button {
                    height: 32px;
                    width: 316px;
                }
            }
        }
    }

    &.confirm-approve-modal {
        height: 394px;

        .ant-modal-content {
            padding: 0;
        }

        .ant-modal-body {
            padding-bottom: 41px;

            .message {
                margin-top: 20px;
                margin-bottom: 40px;
            }

            .form-buttons {
                button {
                    height: 32px;
                    width: 316px;
                }
            }
        }
    }

    .ant-modal-body {
        padding-top: 56px;

        .title {
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: center;
        }

        .message {
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0;
        }
    }
}

.consultation-details-card {
    margin: 15px 0;

    .ant-card {
        border-color: $gray-1;
        background: $gray-1;
    }

    .ant-image-img {
        border-radius: 100px;
        max-height: 70px;
    }

    button.edit-profile {
        font-size: 12px;
        height: 50px;
    }

    .datetime {
        font-weight: normal;
    }

    .attendance-check-icon {
        color: $button-primary-color;
        font-weight: bold;
    }

    .ant-collapse-header {
        display: block !important;
    }

    .profile-card {
        .ant-card-body {
            padding: 0;
        }

        &-title {
            font-weight: bold;
            color: $gray-5;
            margin: 5px 0;
        }

        ul {
            list-style: none;
        }

        ul li::before {
            color: $button-primary-color;
            content: "\2022";
            font-weight: bold;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
        }

        &-schedule {
            padding: 0 20px;
        }

        &-spacing {
            margin: 15px 0;
        }

        &-note {
            color: $gray-5;
        }
    }
}

.ant-form-item-has-error {
    .ant-upload {
        border-color: #ff4d4f;
    }
}

.object-fit-cover {
    -o-object-fit: cover;
    object-fit: cover;
}

.font-roboto {
    font-family: $font-family-roboto;
}
