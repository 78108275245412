@import 'variables';

body#dashboard {
    background: $background-white;

    .site-default-header {
        background-color: $button-primary-color;
        z-index: 2;
    }

    .c-sidebar-nav {
        max-height: calc(100% - 64px);
    }

    .ant-menu-title-content,
    .ant-dropdown-menu-title-content {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
    }

    .site-sidebar {
        height: 100vh;
        z-index: 1030;

        .site-sidebar-header {
            .title {
                color: $background-white;
                font-size: 14px;
                font-weight: 700;
            }
        }

        .site-menu-item {
            //height: auto;
            &:not(.ant-menu-submenu-inline) {
                min-height: 40px;
            }

            white-space: pre-wrap;

            &:not(:first-child) {
                margin-top: 0.5rem;
            }

            .ant-menu-submenu-title {
                //height: auto;
                //margin-top: 0;
                //margin-bottom: 0;
                white-space: pre-wrap;
                line-height: 1.875rem;
            }
        }
    }

    .btn-toggle-account-menu {
        color: $background-white;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }

    .ant-dropdown-link {
        color: $background-white;
    }

    .ant-menu-root {
        flex-direction: column;
        display: flex;
        height: 100%;
        border-right: none;
    }

    .title {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 25px;
    }

    .site-sidebar-header .title {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
    }

    .secondary-button {
        background-color: $button-secondary-color;
        color: black;
        border: 1px solid $button-secondary-outline;
        width: 100px;
    }

    .dashboard-content {
        margin: 135px 175px 100px;
        flex-direction: row;

        .ant-layout-sider {
            padding: 25px 0;
            max-height: calc(100% - 250px);
            background-color: #fff;
            overflow-x: hidden;
        }

        .ant-menu-item-selected {
            background-color: #006bb5;

            &,
            a {
                color: #fff;
            }

            &:after {
                border: none;
            }
        }

        .ant-layout-content {
            margin-left: 300px;
            flex: none;
        }

        .ant-breadcrumb {
            margin-bottom: 10px;
        }

        .content {
            background-color: #fff;
            padding: 20px;
        }

        .notification-count {
            float: right;
            margin: 10px;
        }

        .ant-badge-count {
            box-shadow: 0 0 0 1px $red-3;
        }
    }

    .dashboard-sider {
        overflow: auto;
        height: 100vh;
        position: fixed;
        left: 175px;
    }

    .dashboard-footer {
        background-color: $button-primary-color;
        text-align: center;
        height: 30px;
        padding: 5px 0;
        color: #fff;
        position: fixed;
        bottom: 0;
        width: 100%;
    }

    .dashboard-management {
        button.edit-profile {
            float: right;
            background: $button-primary-color;
            color: #fff;
        }

        .personal-info {
            .title {
                font-size: 16px;
                margin-bottom: 5px;
            }
        }

        button.unsubscribe-button {
            float: right;
        }

        .change-password {
            color: #01a0dd;
            text-decoration: underline;
            margin-bottom: 45px;
            cursor: pointer;
        }
    }

    .dashboard-notifications {
        .notification {
            &-container {
                padding: 10px 20px;
                border-radius: 3px;
                border: 1px solid rgba(196, 196, 196, 0.4);
                margin-bottom: 15px;

                &_read {
                    background-color: $gray-1;
                }

                &_unread {
                    background-color: $yellow-1;
                }
            }

            &-title {
                font-weight: bold;
                font-size: 14px;
            }

            &-btn {
                background-color: $button-secondary-color;
                color: black;
                border: 1px solid $button-secondary-outline;
                width: 100px;
            }
        }
    }

    .unsubscribe-modal {
        .unsubscribe-content {
            padding: 30px;
        }

        .unsubscribe-description {
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0;
            text-align: center;
        }
    }

    .interview-list {
        .title {
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 25px;
        }

        .table-filter {
            float: right;
        }

        .subtitle {
            font-size: 16px;
        }

        &-pagination {
            margin: 15px 0;
        }
    }

    .interview-details {
        .title {
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 25px;
        }

        .grant-checklist {
            margin: 10px 0;
        }

        .consultation-detail {
            margin: 10px 0;
        }

        .cancel-button {
            float: right;
        }

        button.back-button {
            width: 200px;
            margin-top: 30px;
        }

        button.consult {
            margin-top: 30px;
        }

        .consult-note {
            margin: 20px auto;
            width: 60%;
        }
    }

    .dashboard-calendar {
        .meeting-count {
            padding: 15px 20px;
            border-radius: 3px;
            border: 1px solid rgba(196, 196, 196, 0.4);
            margin-bottom: 15px;
            background-color: $gray-1;

            &-title {
                font-size: 16px;
                font-weight: bold;
            }
        }

        .divider {
            margin: 30px 0;
            height: 2px;
            border: none;
            background-color: $button-primary-color;
        }

        .subtitle {
            font-size: 16px;
            color: $gray-4;
        }

        .ant-picker-body {
            z-index: 0;
        }

        .month-picker {
            text-align: right;

            &-text {
                font-weight: bold;
                font-size: 16px;
            }

            .ant-btn {
                border: none;
                color: black;
            }
        }

        ul {
            list-style-type: none !important;
            padding-inline-start: 0;
        }
    }

    .register-modal {
        height: 342px;

        &.register-category-notification-modal {
            height: 292px;

            .ant-modal-content {
                padding: 0;
            }

            .title {
                margin-bottom: 9px !important;
            }

            .ant-modal-body {
                padding-bottom: 70px;
            }

            form {
                margin-left: 86px;
                margin-right: 86px;
            }

            .form-buttons {
                margin-top: 35px;

                button {
                    width: 388px;
                }
            }
        }

        &.register-master-subsidy-modal {
            height: auto;

            .ant-modal-content {
                padding: 0;
            }

            .title {
                margin-bottom: 18px !important;
            }

            .ant-modal-body {
                padding-bottom: 70px;
            }

            form {
                margin-left: 86px;
                margin-right: 86px;
            }

            .form-buttons {
                margin-top: 35px;

                button {
                    width: 388px;
                }
            }
        }

        .ant-modal-body {
            padding: 56px 0 69px 0;
        }

        form {
            margin-left: 135px;
            margin-right: 134px;
        }

        .title {
            font-size: 18px;
        }

        .ant-form-item-label > label {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.85);
        }

        .ant-input:placeholder-shown {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 0;
            text-align: left;
            color: rgba(0, 0, 0, 0.25);
        }

        .btn-link {
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            letter-spacing: 0;
            text-align: center;
            color: $button-primary-color;
        }

        .form-buttons {
            margin-top: 32px;

            button {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                letter-spacing: 0;
                text-align: center;
                height: 32px;
                width: 286px;
                border-radius: 2px;
                padding: 5px 16px 5px 16px;
            }
        }
    }
}
