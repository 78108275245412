@import '../variables';

body#account-register {
    background-image: url('../../images/bg_login.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;

    .ant-layout {
        background: none;
    }

    .ant-layout-header {
        background-color: $button-primary-color;

        .title {
            color: $background-white;
            font-size: 24px;
        }
    }

    .card-register {
        .title {
            font-size: 18px;
        }
    }
}
