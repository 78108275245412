.profiles-management {
    .header-container {
        padding-right: 0px;
    }
    .footer-buttons {
        flex-direction: column;
        margin-top: 10px;
        .cancel-button {
            margin-right: 0px;
            margin-bottom: 10px;
        }
    }
}
