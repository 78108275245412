.notification-management {
    height: 100%;
    display: flex;
    flex-direction: column;

    .header-container {
        display: flex;

        .title {
            color: #444444;
            font-style: normal;
            font-weight: 700 !important;
            font-size: 20px !important;
            line-height: 30px;
        }

        .action-buttons {
            margin-left: auto;

            button {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                color: #ffffff;
                background: #006bb5;
                padding: 5px 39px;
                box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
            }
        }
    }

    .custom-table {
        flex-grow: 1;

        .action-buttons {
            float: none;

            .ant-btn {
                min-width: 100px;

                &:not(:last-child) {
                    margin-right: 10px;
                }
            }

            .detail-button {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                color: rgba(0, 0, 0, 0.85);
                background: #ffffff;
                border: 1px solid #d9d9d9;
                box-sizing: border-box;
                box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
                padding: 5px 35px;
            }

            .delete-button {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                color: #ff4d4f;
                background: #ffffff;
                border: 1px solid #ff4d4f;
                box-sizing: border-box;
                box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
                padding: 5px 35px;
            }
        }

        .ant-table-thead > tr > th {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.85);
        }

        .ant-table-tbody > tr > td {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.85);
        }

        .ant-table-wrapper {
            flex-grow: 1;
        }

        .ant-table {
            flex-grow: 1;
        }

        .ant-pagination {
            justify-content: center !important;
        }

        .ant-spin-nested-loading {
            height: 100% !important;
        }

        .ant-spin-container {
            height: 100% !important;
            display: flex;
            flex-direction: column;
        }
    }

    .notification-container {
        .notification-correction {
            color: #000000;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 30px;
            margin-top: 10px;
        }

        .notification-category {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 22px;
            color: #999999;

            .notification-date {
                margin-left: auto;
            }
        }

        hr {
            border: 1px solid #999999;
            margin-bottom: 15px;
            margin-top: 13px;
        }

        .notification-content {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 22px;
            color: #444444;
        }
    }
}

.notification-edit {
    .header-container {
        display: flex;

        .title {
            color: #444444;
            font-style: normal;
            font-weight: 700 !important;
            font-size: 20px !important;
            line-height: 30px;
        }

        .action-buttons {
            margin-left: auto;
            margin-bottom: 22px;

            button {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
            }

            .draft-button {
                background: #ffffff;
                border: 1px solid #d9d9d9;
                box-sizing: border-box;
                box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
                color: rgba(0, 0, 0, 0.85);
                padding: 5px 32px;
            }

            .send-button {
                margin-left: 10px;
                background: #006bb5;
                box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
                color: #ffffff;
                padding: 5px 39px;
            }
        }
    }

    .notification-content {
        p {
            color: rgba(0, 0, 0, 0.85);
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            margin-bottom: 8px;
        }

        .p-margin-top {
            margin-top: 18px;
        }
        .email-editor {
            height: 200px;
        }
    }
}
