@import '../variables';

.base-layout-container {
    .background-white {
        height: calc(100vh - 64px) !important;
    }
}

.site-layout-background {
    height: 100% !important;
}

.business-management {
    height: 100%;
    display: flex;
    flex-direction: column;

    .header-container {
        display: flex;
        margin-top: 24px;
    }

    .title {
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        color: #444444;
    }

    .action-buttons {
        margin-left: auto;

        .btn-export {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            min-width: 104px;
            background: $button-primary-color;
            color: $button-secondary-color;
        }

        button.filter-button {
            color: black;
            border: 1px solid $gray-6;
            padding: 3px 10px;
            background: white;
        }

        .ant-input-search-button {
            border: 1px solid $gray-6 !important;
            background-color: white;
        }

        .ant-btn-dangerous {
            background: $red-1;
        }
    }

    .ant-list-item {
        display: block;
        padding: 5px 0;
    }

    .ant-list-split .ant-list-item {
        border-bottom: none;
    }

    .ant-collapse {
        border: 1px solid $gray-1;
    }

    .ant-collapse-item {
        border-bottom: $gray-1;
    }

    .ant-collapse .ant-collapse-item {
        background: $gray-1;
    }

    .ant-collapse-content {
        border-top: 1px solid $gray-1;
    }

    .ant-collapse-header {
        display: block;
    }

    .branch-sorter {
        margin-left: 10px;
        font-size: 14px;
        font-weight: bold;

        a {
            color: black;
        }
    }

    .total-record {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
        color: #000000;
    }

    .custom-table {
        flex-grow: 1;

        .action-buttons {
            float: none;

            .ant-btn {
                min-width: 100px;

                &:not(:last-child) {
                    margin-right: 10px;
                }
            }

            .detail-button {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 22px;
                background: $background-white;
                color: #000000;
                border: 1px solid #d9d9d9;
            }
        }

        .ant-layout {
            height: 100% !important;
        }

        .ant-table-thead > tr > th {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
        }

        .ant-table-wrapper {
            flex-grow: 1;
        }

        .ant-table-tbody > tr > td {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
        }

        .ant-table {
            flex-grow: 1;
        }

        .ant-pagination {
            justify-content: center !important;
            padding: 3px 0 27px;
        }

        .ant-spin-nested-loading {
            height: 100% !important;
        }

        .ant-spin-container {
            height: 100% !important;
            display: flex;
            flex-direction: column;
        }
    }
}

.business-detail {
    .header-container {
        display: flex;
    }

    .title-detail {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        color: #444444;
    }

    .view-business-profile {
        margin: 16px 0;
    }

    .action-buttons {
        margin-left: auto;

        button.filter-button {
            color: black;
            border: 1px solid $gray-6;
            padding: 3px 10px;
            background: white;
        }

        .block-search {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
        }

        .ant-input-search-button {
            border: 1px solid $gray-6 !important;
            background-color: white;
        }

        .ant-btn-dangerous {
            background: $red-1;
        }
    }

    .background-profile,
    .personal-info {
        background: #f5f5f5;
        border-radius: 3px;
        padding: 25px 40px;
    }

    .personal-info {
        .title {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
        }
    }

    .background-profile {
        width: 100%;

        .text-normal {
            color: #000000;
        }

        .text-bold {
            font-weight: 800;
            color: #000000;
        }

        .label-style {
            font-family: 'Hiragino Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 22px;
            color: #000000;
        }

        .info-profile {
            font-family: 'Hiragino Sans';
            font-style: normal;
            font-weight: 800;
            font-size: 12px;
            line-height: 22px;
            color: #000000;
        }
    }

    .personal-info {
        margin-top: 16px;

        .title-manager {
            font-family: 'Hiragino Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 22px;
            color: #000000;
            margin-bottom: 4px;
        }

        .info-manager {
            font-family: 'Hiragino Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.85);
        }
    }

    .status-info-icon {
        color: #999999;
        margin: 0 3px;
    }

    .lighter-text {
        color: #000000;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
    }

    .title-text {
        font-weight: 700;
    }

    .edit-btn {
        width: 134px;
        background-color: #ffffff;
        color: #000000;
        border: 1px solid #d9d9d9;
    }

    .list-interview-card-container {
        align-items: center;
        margin: 9px 0;
        justify-content: center;
    }

    .business-dropdown {
        background-color: white;
        color: black;
        border-color: black;
        border-style: solid;
        border-width: 0;
    }

    .list-pagination {
        justify-content: center;
        align-items: center;
        height: 100px;
    }

    .total-record {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 10px;
        margin-top: 4px;
        color: #000000;
    }

    .overflow-text {
        overflow-wrap: break-word;
    }
}

.management-modal {
    .search-btn {
        width: 100px;
    }

    .border-width {
        height: 1px;
        margin: 30px 0 15px;
        background-color: #006bb5;
    }

    .confirm-btn {
        justify-content: center;
        align-items: center;
    }

    .submit-btn {
        width: 315px;
    }

    .keep-btn {
        background-color: #006bb5;
        border: #006bb5 solid 1px;
    }

    .secretary-info {
        margin: 0 30px 0 10px;
    }

    .not-present {
        margin-left: 60px;
    }
}

.appointment-detail {
    background-color: #ffffff;

    .appointment-title {
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        color: #444444;
        line-height: 30px;
        margin: 0px 0 20px 0;
    }
    
    .appointment-schedule-title {
        font-size: 15px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .appointment-schedule-description {
        font-weight: normal;
        font-size: 13px;
        color: $gray-4;
    }

    .appointment-row {
        background-color: #f2eeff;
        font-weight: 400;
        font-size: 12px;
        min-height: 72px;
        justify-content: space-between;
        align-items: center;
        padding: 18px;
        line-height: 17.38px;
    }

    .appointment-btn {
        margin-top: 21px;
        justify-content: center;
    }

    .appointment-textarea {
        margin-top: 9px;
    }

    .appointment-cancel {
        background-color: #ff4d4f;
        border: #ff4d4f;
        width: 241px;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        margin-left: 24px;
        font-style: normal;
    }

    .appointment-default {
        width: 241px;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        margin-left: 24px;
        font-style: normal;
    }

    .appointment-text {
        font-weight: 400;
        font-size: 12px;
        line-height: 17.38px;
        font-style: normal;
    }

    .max-lines {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        display: block;
        max-width: 90%;
    }

    .appointment-label {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        font-style: normal;
        color: #000000d9;
        margin: 21px 0 8px 0;
    }

    .label-consultation {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        color: #828282;
        padding-bottom: 8px;
    }

    .schedule-consultation {
        margin: 22px 0 18px 0px;
    }

    .datong-life-bearer {
        margin: 0 0 3px 0px;
    }

    .datong-label {
        font-weight: 700;
        font-size: 14px;
    }

    .schedule {
        justify-content: space-between;
        display: flex;
        align-items: center;
    }

    .detail-consultation-label {
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        font-style: normal;
        color: #000000d9;
        margin: 21px 0 15px 0;
    }

    .color-icon {
        color: #006bb5;
    }

    .primary {
        background-color: #006bb5;
    }

    .copy-btn {
        background-color: #e6f7ff;
        color: #006bb5;
        border-color: #006bb5;
    }

    .row-form {
        align-items: center;
    }

    .border {
        height: 1px;
        margin: 37px 0 15px;
        background-color: #e0e0e0;
    }

    .image-user {
        width: 69px;
        height: 69px;
    }

    .info-user {
        font-weight: 400;
        font-size: 12px;
        line-height: 17.38px;
        font-style: normal;
        color: #000000;
    }

    .card-appoint {
        align-items: center;
    }

    .schedule {
        justify-content: space-between;
    }

    .schedule-text {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #000000;
    }

    .block-bottom {
        margin: 10px 0;
    }

    .schedule-color {
        background-color: #006bb5;
        color: white;
    }

    .content {
        font-weight: 700;
    }
}

@media all and (min-width: 1024px) {
    .business-detail {
        .action-buttons {
            position: absolute;
            right: 47px;
            top: 147px;
            z-index: 1;
        }
    }
}
