@import '../variables';

.subsidy-addition {
    padding-bottom: 50px;
    .header-container {
        display: flex;
    }
    .title {
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        color: #444444;
        margin-bottom: 12px !important;
    }
    .ant-tabs-tab-btn {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
    }
    .ant-tabs-tab-active .ant-tabs-tab-btn {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
    }
    .date {
        margin-top: 6px;
    }
    .action-buttons {
        margin-left: auto;
        margin-top: 0px;
        .ant-btn-dangerous {
            background: $red-1;
        }
        .ant-list-item {
            display: block;
            padding: 5px 0;
        }

        .ant-list-split .ant-list-item {
            border-bottom: none;
        }

        .ant-collapse {
            border: 1px solid $gray-1;
        }

        .ant-collapse-item {
            border-bottom: $gray-1;
        }

        .ant-collapse .ant-collapse-item {
            background: $gray-1;
        }

        .ant-collapse-content {
            border-top: 1px solid $gray-1;
        }

        .ant-collapse-header {
            display: block;
        }
        .ant-btn {
            width: 96px;
            margin-left: 8px;
            margin-top: 5px;
            padding-left: 12px;
            padding-right: 12px;
        }
        .branch-sorter {
            margin-left: 10px;
            font-size: 14px;
            font-weight: bold;

            a {
                color: black;
            }
        }
    }
    .sub-title {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #999999;
        height: 37px;
        margin: 0px;
        display: table-cell;
        vertical-align: bottom;
    }
    .ant-form {
        margin-top: 15px;
    }
    .ant-form-item-label {
        text-align: left;
        font-size: 12px;
        > label {
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 17px;
            vertical-align: middle;
            color: #000000;
        }
    }
    .radio-button-checked {
        color: #006bb5;
    }

    .form-title {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #000000;
        border-left: solid #006bb5;
        border-width: 4px;
        padding-left: 15px;
        margin: 0 5px 10px;
    }
    .form-title-2 {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #000000;
        border-left: solid #006bb5;
        border-width: 4px;
        padding-left: 15px;
        margin: 50px 5px 10px;
    }
    .ant-col-8 {
        display: flex;
        justify-content: space-between;
        padding-left: 40px;
    }
    .interview-target-radio {
        .ant-radio-group {
            display: flex;
            justify-content: left;
        }
    }
    .checkbox-item {
        margin-bottom: 10px;
    }
    .date {
        font-weight: 700;
        font-size: 12px;
        color: #000000;
        line-height: 17px;
        height: 32px;
        margin: 0px;
        display: table-cell;
        vertical-align: middle;
    }
    .radio-btn {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        justify-content: space-between;
        display: flex;
    }
    .ant-row.ant-form-item {
        margin-bottom: 6px;
    }
}
