$border-radius: 2px;

$button-primary-color: #006bb5;
$button-secondary-color: #ffffff;
$button-secondary-outline: #d9d9d9;

$gray-1: #f2f2f2;
$gray-2: #f5f5f5;
$gray-3: #e0e0e0;
$gray-4: #999999;
$gray-5: #828282;
$gray-6: #d9d9d9;

$yellow-1: #fff8d4;
$yellow-2: #efcb0d;
$yellow-3: #e0c015;

$green-1: #f6ffed;
$green-2: #b7eb8f;
$green-3: #66b828;

$purple-1: #f2eeff;
$purple-2: #bca7ef;
$purple-3: #7a37a1;

$orange-1: #fff5ec;
$orange-2: #ffd9aa;
$orange-3: #f4a353;

$brown-1: #f1ebeb;
$brown-2: #b8aba3;
$brown-3: #843c0c;

$red-1: #fce6e6;
$red-2: #f59a9b;
$red-3: #ff4d4f;

$light-blue-1: #e6f7ff;
$light-blue-2: #91d5ff;
$light-blue-3: #01a0dd;
$light-blue-4: #3574B4;

$background-white: #ffffff;
$text-black: #444444;
$button-black-color: rgba(0, 0, 0, 0.85);

// Font-family
$font-family-noto-sans: 'Noto Sans JP', sans-serif;
$font-family-roboto: 'Roboto';

/**
   Reusable custom color classes
 */
 $colors: (
    primary-color: $button-primary-color,
    red-1: $red-1,
    red-2: $red-2,
    red-3: $red-3,
    light-blue-1: $light-blue-1,
    light-blue-2: $light-blue-2,
    light-blue-3: $light-blue-3,
    light-blue-4: $light-blue-4,
    orange-1: $orange-1,
    orange-2: $orange-2,
    orange-3: $orange-3,
    green-1: $green-1,
    green-2: $green-2,
    green-3: $green-3,
);

@each $className, $color in $colors {
    .#{$className} {
    color: $color !important;
    }
}