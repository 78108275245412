@import '../variables';

.loan-detail {
    .block-form {
        .block-building-name {
            margin-top: 0;
        }
    }
    .display-button {
        float: right;
    }
    .block-status {
        margin-top: 12px;
    }
    .block-form {
        .has-two-input {
            margin-left: 0;
        }
    }
}
