@import '../variables';

.email-detail {
    .header-container {
        display: flex;
    }

    .title {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        color: #444444;
    }

    .subject-title {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.85);
    }

    .action-buttons {
        float: none;

        .ant-btn {
            min-width: 100px;

            &:not(:last-child) {
                margin-right: 10px;
            }
        }
    }
    .block-name {
        margin-bottom: 0;
    }
    .email-editor {
        margin-top: 12px;
        margin-bottom: 20px;
    }

    .email-radio-button {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #006bb5;
        .label-block-status {
            align-self: center;
        }
        .block-status {
            margin-bottom: 0;
        }
    }

    .edit-email-content {
    }

    .save-button {
        background-color: #006bb5;
        color: #fff;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        min-width: 100px;
    }

    .cancel-button {
        min-width: 100px;

        &:not(:last-child) {
            margin-right: 10px;
        }

        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        background: $background-white;
        border: 1px solid #d9d9d9;
    }

    .ant-radio-wrapper:hover .ant-radio,
    .ant-radio:hover .ant-radio-inner,
    .ant-radio-input:focus + .ant-radio-inner {
        border-color: #d9d9d9;
    }

    .ant-radio-input:focus + .ant-radio-inner {
        box-shadow: 0 0 0 0;
    }

    .radio-button-checked {
        color: #006bb5;
    }

    .radio-button-not-checked {
        color: rgba(0, 0, 0, 0.25);
    }

    .ck-editor__editable {
        min-height: 300px;
    }
}
