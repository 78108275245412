.dashboard-container {
    background-color: #fff;

    .title {
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
    }

    .dashboard-row {
        margin-bottom: 5px;
    }
}
