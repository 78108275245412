@import '../variables';

.interview-management {
    .header-container {
        display: flex;
    }

    .title {
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        color: #444444;
        margin-bottom: 12px !important;
    }

    .action-buttons {
        margin-left: auto;

        .block-status {
            min-width: 175px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
        }

        .block-datepicker {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.85);

            button.ant-btn-primary {
                background-color: #006bb5;
                color: #ffffff;
                // padding: 5px 16px;
            }
        }

        .block-search {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            min-width: 242px;
        }

        button.filter-button {
            color: black;
            border: 1px solid $gray-6;
            padding: 3px 8px;
            background: white;
            max-width: 32px;
        }

        .ant-input-search-button {
            border: 1px solid $gray-6 !important;
            background-color: white;
        }

        .ant-btn-dangerous {
            background: $red-1;
        }
    }

    .ant-list-item {
        display: block;
        padding: 5px 0;
    }

    .ant-list-split .ant-list-item {
        border-bottom: none;
    }

    .ant-collapse {
        border: 1px solid $gray-1;
    }

    .ant-collapse-item {
        border-bottom: $gray-1;
    }

    .ant-collapse .ant-collapse-item {
        background: $gray-1;
    }

    .ant-collapse-content {
        border-top: 1px solid $gray-1;
    }

    .ant-collapse-header {
        display: block;
    }

    .branch-sorter {
        margin-left: 10px;
        font-size: 14px;
        font-weight: bold;

        a {
            color: black;
        }
    }

    .total-record {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 10px;
        margin-top: 4px;
        color: #000000;
    }

    .custom-table {
        .action-buttons {
            float: none;

            .ant-btn {
                min-width: 100px;

                &:not(:last-child) {
                    margin-right: 10px;
                }
            }

            .edit-button {
                border: 1px solid $button-secondary-outline;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 22px;
                color: rgba(0, 0, 0, 0.85);
            }
        }

        .ant-table-cell {
            height: 56px;
        }

        .ant-table-thead > tr > th {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
        }

        .ant-pagination {
            justify-content: center !important;
            padding: 3px 0 27px;
        }

        .ant-table-tbody > tr > td {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
        }
    }
}


.application-specialist-card {
    margin: 15px 0;

    .ant-card {
        border-color: $gray-1;
        background: $gray-1;
    }

    .ant-image-img {
        border-radius: 100px;
        max-height: 70px;
    }

    button.edit-profile {
        font-size: 12px;
        height: 50px;
    }

    .datetime {
        font-weight: normal;
    }

    .attendance-check-icon {
        color: $button-primary-color;
        font-weight: bold;
    }

    .ant-collapse-header {
        display: block !important;
    }

    .profile-card {
        .ant-card-body {
            padding: 0;
        }

        &-title {
            font-weight: bold;
            color: $gray-5;
            margin: 5px 0;
        }

        ul {
            list-style: none;
        }

        ul li::before {
            color: $button-primary-color;
            content: "●";
            font-weight: bold;
            display: inline-block;
            width: 2em;
        }

        &-schedule {
            padding: 0 20px;
        }

        &-selection {
            column-count: 2;
            -webkit-column-count: 2;
            -moz-column-count: 2;
            margin-top: 10px;

            label {
                line-height: 30px;
            }

            .ant-radio {
                margin-right: 15px;
            }
        }

        &-spacing {
            margin: 15px 0;
        }

        &-note {
            color: $gray-5;
        }
    }
}