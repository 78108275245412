.notification-content-management,
.category-management {
    .header-container {
        flex-direction: column;
        margin-bottom: 10px;
        margin-top: 15px;

        .button-left {
            margin: 0px 0px 10px 0px;
        }
        .button-style {
            margin: 0px 0px 10px 0px;
        }
        .action-buttons {
            margin: auto;
        }
    }
    .custom-table {
        .action-button {
            .view-btn {
                padding-left: 9%;
            }
        }
    }
}
