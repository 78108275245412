@import 'variables';

body#public-support {
    .container {
        margin: 55px auto;
    }

    section.header {
        position: relative;
        height: 800px;
        background: url('../images/kouteki_landpage_header.png') center no-repeat;
        width: 100%;
        background-size: cover;
        background-position-y: 70%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 30%;
        }

        .content {
            height: 800px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    section.sub-header {
        position: relative;
        background: url('../images/kouteki_header.png') center no-repeat;
        width: 100%;
        background-size: cover;
        height: 225px;

        img {
            width: 30%;
        }

        .content {
            background: rgba(19, 90, 117, 0.5);
            background-size: cover;
            width: 100%;
            justify-content: center;
            align-items: center;
            display: flex;

            .title {
                margin-top: 10%;
                font-size: 36px;
                color: #ffffff;
            }
        }
    }

    section.content {
        padding: 20px 182px 100px 182px;
        min-height: calc(100vh - (225px + 250px)); // (navbar + footer)
    }

    section.home-content {
        &:not(:last-child) {
            margin-bottom: 50px !important;
        }

        .title {
            color: #01a0dd;
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 15px;
        }

        .title,
        p {
            text-align: center;
        }
    }

    section.diagnose-result-container {
        .title {
            color: #01a0dd;
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 24px;
        }
    }

    footer.landing-footer {
        background-image: url('../images/footer_banner.png');
        color: #ffffff;

        .footer-wrapper {
            background: linear-gradient(180deg, rgba(0, 151, 254, 0.7) -8.91%, rgba(0, 12, 20, 0.7) 74%);
        }
    }

    .search-form-card form {
        .ant-form-item-label label {
            font-weight: bold;
        }

        .ant-btn {
            background-color: #2a6cb0;
            color: #fff;
            height: 60px;
            width: 180px;

            &:hover {
                background-color: #017fd6;
            }
        }
    }

    .service-card {
        height: 100%;
        text-align: center;

        img {
            height: 200px;
        }

        .card-title {
            margin: 15px 0;
            font-size: 13px;
            font-weight: bold;
        }

        p {
            font-size: 12px;
            text-align: left;
        }
    }

    .ant-carousel {
        .carousel-page {
            padding: 20px 10px;
        }

        .slick-prev,
        .slick-prev:hover {
            color: #2a6cb0;
        }

        .slick-next,
        .slick-next:hover {
            color: #2a6cb0;
        }

        .ant-btn {
            color: #2a6cb0;
            border-color: #2a6cb0;
        }
    }

    .search-content {
        .page-count {
            font-weight: bold;
            font-size: 18px;
            text-align: center;
            margin-bottom: 10px;
        }

        .search-result {
            .ant-checkbox-wrapper {
                width: calc(100% - 60px);
            }

            .ant-checkbox-wrapper > span:nth-child(2) {
                line-height: 18px;
                overflow: hidden;
            }

            .title,
            .description {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .title {
                width: 45vh;
                margin-right: 15px;
                font-weight: bold;
            }

            .ant-list-split .ant-list-item {
                border: 1px solid #dedede;
                margin-bottom: 15px;
                padding: 12px;
                border-radius: $border-radius;

                &:hover {
                    background-color: #f0f0f0;
                }
            }
        }

        .submit-button {
            height: 32px;
            width: 150px;
        }
    }

    .carousel-page,
    .search-result {
        .ant-btn:not(:disabled) {
            border-color: #006bb5;
            color: #006bb5;

            &:hover {
                background-color: #006bb5;
                color: #fff;
            }
        }
    }

    .note {
        font-size: 12px;
        font-weight: 400;
        line-height: 22px;
    }

    .consult-badge {
        background: #66b828;
        color: #ffffff;
        padding: 10px;
        text-align: center;
        display: inline-block;
    }

    .diagnose-list {
        background: #f2f2f2;
        align-items: center;
        margin-bottom: 20px;
    }

    .footer-items a {
        color: #ffffff !important;
    }

    .search-funds-details {
        .content {
            white-space: pre-line;
        }

        .title {
            font-size: 24px;
            color: #01a0dd;
            display: inline-block;
            font-weight: bold;
            margin-bottom: 10px;
        }

        .badge {
            background-color: #f6ffed;
            border: 1px solid #b7eb8f;
            border-radius: 2px;
            color: #52c41a;
            font-size: 12px;
            padding: 2px 5px;
            vertical-align: middle;
            margin-left: 10px;
        }

        section {
            margin-bottom: 20px;

            .name {
                color: #01a0dd;
                font-weight: bold;
            }
        }

        .helper-text {
            border: 1px solid #000000;
            color: #ff4d4f;
            padding: 5px;
            display: inline-block;
        }

        .requirements-section {
            margin-top: 30px;
            background-color: #f0f0f0;
            text-align: center;
            padding: 50px;

            .requirements {
                font-size: 36px;
                color: #444444;
                font-weight: bold;
            }
        }

        .more-details {
            background-color: #eaf0f7;
            color: #2a6cb0;
            padding: 30px 20px;
            width: 100%;
            display: flex;
            align-items: center;

            button.ant-btn {
                margin-left: auto;
                background-color: #006bb5;
                color: #fff;
                height: 50px;
                width: 120px;
            }
        }
    }

    button.diagnose-button {
        height: 40px;
        width: 320px;
        background-color: #006bb5;
        color: #fff;
    }
}
