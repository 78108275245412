@import '../variables';

.diagnosticians-detail {
    .header-container {
        display: flex;
    }

    .title {
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        color: #444444;
    }

    .block-form {
        .row-form {
            justify-content: center;
        }

        .note-upload,
        .ant-form-item-extra {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.45);
        }

        .text-upload {
            margin-top: 8px;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.45);
        }

        .ant-form-item {
            margin-bottom: 17px;
        }

        .ant-form-item-required,
        .ant-form-item-label {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.85);
        }

        ::placeholder,
        .ant-input,
        .ant-checkbox-wrapper span:nth-child(2),
        .ant-radio-wrapper span:nth-child(2),
        .ant-upload > button span:nth-child(2) {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
        }

        .ant-select-selection-item-content {
            color: #91d5ff;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 20px;
        }

        .block-button {
            margin-top: 79px;
            padding-bottom: 104px;
        }

        .btn-back {
            width: 200px;
            border: 1px solid #1890ff;
            color: #1890ff;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
        }

        .btn-edit {
            width: 200px;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
        }

        .block-building-name {
            margin-top: -24px;
        }
    }

    .ant-list-item {
        display: block;
        padding: 5px 0;
    }

    .ant-list-split .ant-list-item {
        border-bottom: none;
    }

    .ant-collapse {
        border: 1px solid $gray-1;
    }

    .ant-collapse-item {
        border-bottom: $gray-1;
    }

    .ant-collapse .ant-collapse-item {
        background: $gray-1;
    }

    .ant-collapse-content {
        border-top: 1px solid $gray-1;
    }

    .ant-collapse-header {
        display: block;
    }

    .branch-sorter {
        margin-left: 10px;
        font-size: 14px;
        font-weight: bold;

        a {
            color: black;
        }
    }

    .total-record {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 10px;
        margin-top: 4px;
        color: #000000;
    }

    .custom-table {
        .action-buttons {
            float: none;

            .ant-btn {
                min-width: 100px;

                &:not(:last-child) {
                    margin-right: 10px;
                }
            }

            .edit-button {
                border: 1px solid $button-secondary-outline;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 22px;
            }
        }

        .ant-table-thead > tr > th {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
        }

        .ant-table-tbody > tr > td {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
        }
    }

    //test
    .tab-container {
        background: #f5f5f5;
        border-radius: 3px;
        margin-top: 20px;
    }

    .info-tab {
        padding: 20px 20px 20px 20px;
    }

    .info-title {
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 17px;

        color: #000000;
    }

    .info-content {
        font-weight: 500;
    }

    .info-page {
        margin-top: 20px;
        text-align: center;
        margin-bottom: 20px;
    }

    .tab-header {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 27px;
        color: #444444;
    }
}

.diagnosticians-account-detail {
    .action-buttons {
        text-align: right;
        margin-bottom: 40px;

        button {
            box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
            border-radius: 2px;
            padding: 5px 35px;
            color: #ffffff;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
        }

        .edit-button {
            background: #006bb5;
            margin-right: 8px;
        }

        .delete-button {
            background: #ff4d4f;
        }
    }
}

.diagnosticians-select {
    width: 243px;
}
