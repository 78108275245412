.base-layout-container {
    .background-white {
        background-color: #fff !important;
    }
}

body#dashboard {
    .register-modal {
        form {
            margin-left: 35px;
            margin-right: 34px;
        }
    }
}
