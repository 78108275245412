.dashboard-navbar {
    position: fixed;
    top: 0;
    padding: 5px 136px;
    display: flex;
    width: 100%;
    z-index: 100;
    background-color: #006bb5;
    height: 75px;

    .logo {
        display: flex;
        flex-direction: column;

        img {
            height: 45px;
            width: max-content;
            align-self: center;
        }

        span {
            font-size: 12px;
            color: #fff;
            letter-spacing: 1px;
            line-height: normal;
        }
    }

    .username {
        margin-left: auto;
        align-self: center;
        color: #fff;
    }
}
