.loan-management {
    .title {
        margin-bottom: 10px;
    }

    .header-container {
        flex-direction: column;
        margin-bottom: 10px;
    }

    .action-buttons {
        margin-left: 0;
    }
}
