.export-container {
    background-color: #fff;
    padding-bottom: 30px;

    .title {
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
    }
}
