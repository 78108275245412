@import '../variables';

.labor_social_navbar {
    top: 0;
    padding: 5px 136px;
    display: flex;
    width: 100%;
    z-index: 100;
    background-color: $button-primary-color;
    height: 75px;

    .buttons {
        margin-left: auto;
        align-self: center;
    }

    .buttons button {
        height: 40px;
        min-width: 160px;
        background-color: $button-primary-color;
        color: $gray-1;
        border: none;

        &:hover {
            background-color: $gray-1;
            color: $light-blue-3;
        }
    }

    .title {
        font-size: 24px;
        color: $button-secondary-color;
        letter-spacing: 1px;
    }
}
