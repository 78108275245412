.login-form-container {
    margin: 25vh auto;
    display: flex;
    align-items: center;

    .login {
        background-color: white;
        height: auto;
        padding: 50px 13%;
        width: 600px;
    }

    .title {
        color: rgba(0, 0, 0, 0.85);
        font-size: 18px;
        line-height: 24px;
        font-weight: 700;
        text-align: center;
    }

    .sub-title {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        text-align: center;
        width: 100%;
        & > label::after {
            content: '';
        }
        .ant-form-item-required {
            color: #656565;
        }
    }
    .ant-input-password {
        padding: 4px 12px;
    }
    .ant-input {
        padding: 4px 12px;
    }
    .link {
        padding: 10px 0;
    }
}
