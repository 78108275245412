.setting-container {
    background-color: #fff;
    .title {
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        color: #444444;
    }
    .label {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: #000000;
        text-align: center;
        margin: 0px 5px;
    }

    .setting-row {
        margin-bottom: 5px;
        display: flex;
        align-items: center;
    }
    .setting-save {
        background-color: #006bb5;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
    }
    .setting-note {
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 22px;
        color: #999999;
    }
}
