@import '../variables';

.grant-detail {
    label {
        white-space: normal;
        height: auto;
    }

    .header-container {
        display: flex;
    }

    .title {
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 12px !important;
    }

    .edit-button {
        padding: 5px 33px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        background: $button-primary-color;
        box-shadow: 0 2px 0 rgba(0, 0, 0, 0.043);
        color: $button-secondary-color;
    }

    .display-button {
        background: #66b828;
        font-family: 'Noto Sans JP';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #ffffff;
        padding: 5px 13px 3px 13px;
        border-radius: 2px;
    }

    .text-updated-at {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #999999;
    }

    .block-date > div,
    .block-status > div {
        align-self: center;
    }

    .block-status {
        margin-bottom: 12px;

        div:first-child {
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 17px;

            color: #000000;
        }

        div:nth-child(2) {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 17px;
            color: #000000;
        }

        button {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
        }
    }

    .block-sub-title {
        margin-bottom: 12px;

        .sub-title {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            color: #000000;
            padding: 2.5px 0 2.5px 16px;
            border-left: 4px solid #006bb5;
        }
    }

    .block-sub-title-second {
        margin-top: 28px;
    }

    .block-form {
        padding-bottom: 20px;

        .ant-form-item {
            margin-bottom: 12px;
        }

        .grant-color-disnable {
            color: #444444;
        }

        label {
            white-space: normal;
            height: auto;
        }

        .ant-form-item-label > label {
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 17px;
            color: #000000;
        }

        .has-two-input {
            @media all and (min-width: 768px) {
                padding-right: 0px !important;
            }
        }

        .ant-checkbox-wrapper + .ant-checkbox-wrapper {
            margin-left: 0;
        }

        .checkbox-item {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
        }
    }
}
