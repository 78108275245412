@import '~antd/dist/antd.css';
@import 'bootstrap';
@import 'variables';

// components
@import 'components/landing_navbar';
@import 'components/landing_footer';
@import 'components/dashboard_navbar';
@import 'components/inquiry';
@import 'components/interview';
@import 'components/labor_social_navbar';
@import 'components/application_detail';

//desktop
@import 'desktop/base_layout';
@import 'desktop/diagnosticians';
@import 'desktop/accounts';
@import 'desktop/business-management';
@import 'desktop/dashboard_custom';
@import 'desktop/login';
@import 'desktop/profiles';
@import 'desktop/trash';
@import 'desktop/emails';
@import 'desktop/master_data';
@import 'desktop/edit_template_email.scss';
@import 'desktop/diagnostician_detail';
@import 'desktop/account_detail';
@import 'desktop/business-management';
@import 'desktop/interview';
@import 'desktop/account_register';
@import 'desktop/loan';
@import 'desktop/notification-management';
@import 'desktop/subsidy';
@import 'desktop/grant';
@import 'desktop/notification-content-management';
@import 'desktop/company_management';
@import 'desktop/master_data_subsidy';
@import 'desktop/master_data_grant';
@import 'desktop/export';
@import 'desktop/setting';
@import 'desktop/loan_detail';
@import 'desktop/grant-addition';
@import 'desktop/subsidy_detail';
@import 'desktop/grant-detail';
@import 'desktop/search_log';
@import 'desktop/subsidy_addition';

// pages
@import 'landing';
@import 'public_support';
@import 'work_regulation';
@import 'dashboard';
@import 'labor_social';

html,
body {
    color: $text-black;
    margin: 0;
    font-family: $font-family-noto-sans;
}

.loader {
    background: url('../images/loader.gif') rgba(255, 255, 255, 0.5) center no-repeat;
    background-size: 7%;
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 9999;
    transition: 0.3s all ease-in-out;
    opacity: 1;
}

.helper-text {
    font-size: 12px;
    color: #747272;
}

.fade-enter {
    opacity: 0;
}

.fade-exit {
    opacity: 1;
}

.fade-enter-active {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
}

.fade-enter-active,
.fade-exit-active {
    transition: opacity 300ms;
}

.ant-btn,
.ant-modal-content,
.ant-input {
    border-radius: $border-radius !important;
}

.ant-input[readonly] {
    background: #f5f5f5;
}

.link {
    text-decoration: underline;
    cursor: pointer;
}

.breadcrumbs {
    padding: 60px 182px 30px 182px;
}

.text-center {
    text-align: center;

    &.title {
        font-size: 18px !important;
    }
}

.ant-carousel {
    .slick-list {
        margin: 0 30px;
    }

    .slick-prev,
    .slick-next {
        top: 0;
        height: 100%;
        display: flex !important;
        align-items: center;
        margin: 0;
        font-size: 24px;
        background: white !important;
    }

    .slick-prev,
    .slick-next,
    .slick-prev:hover,
    .slick-next:hover {
        color: currentColor;
        z-index: 3;
    }

    .slick-prev,
    .slick-prev:hover {
        left: 2px;
    }

    .slick-next,
    .slick-next:hover {
        right: 2px;
    }
}

.login-modal,
.forgot-password-card,
.forgot-password-modal,
.change-password-modal,
.interview-cancel-modal {
    width: 600px !important;
}

.eligibility-modal {
    width: 800px !important;
}

.login-modal,
.forgot-password-card,
.forgot-password-modal,
.registration-card,
.eligibility-modal,
.change-password-modal,
.interview-cancel-modal {
    .ant-modal-body {
        padding: 50px 13%;
    }

    .title {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 15px;
        text-align: center;
    }

    .subtitle {
        font-size: 12px;
        text-align: center;
    }

    form {
        margin: 35px auto;
        width: 300px;

        .link {
            display: block;
            margin: 20px 0;
            font-size: 13px;
            color: #656565;
        }
    }

    .helper-text {
        font-size: 12px;
        color: #747272;

        a {
            text-decoration: none;
        }
    }
}

button.ant-btn-primary {
    background-color: $button-primary-color;
    color: #ffffff;
}

.ant-btn-background-ghost.ant-btn-primary {
    color: $button-primary-color;
    border-color: $button-primary-color;
}

.success-modal {
    text-align: center;
    align-content: center;

    .ant-modal-body {
        padding-bottom: 3rem;

        .title {
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
        }

        img {
            margin: 30px;
        }

        .message {
            margin: 10px 10px;
        }
    }
}

.ant-modal-wrap {
    z-index: 1040;
}

.btn-link {
    color: $button-primary-color;
    border-bottom-color: $button-primary-color !important;
}

.ant-dropdown-item-selected {
    background-color: $button-primary-color;
    color: $background-white;
}

.ant-menu-light {
    .ant-menu-dark,
    .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) {
        .ant-menu {
            &.ant-menu-sub {
                background-color: #fafafa;

                .ant-menu-item {
                    .ant-menu-title-content {
                        color: rgba(0, 0, 0, 0.85);
                    }

                    & > span > a {
                        color: rgba(0, 0, 0, 0.85);
                    }
                }

                .ant-menu-submenu {
                    .ant-menu-submenu-title {
                        .ant-menu-title-content {
                            color: rgba(0, 0, 0, 0.85);
                        }

                        & > i {
                            &::after {
                                color: rgba(0, 0, 0, 0.85);
                            }

                            &::before {
                                color: rgba(0, 0, 0, 0.85);
                            }
                        }
                    }

                    &.ant-menu-submenu-item {
                        .ant-menu-submenu-arrow::before,
                        .ant-menu-submenu-arrow::after {
                            background: rgba(0, 0, 0, 0.85);
                        }
                    }
                }
            }
        }

        .site-menu-item {
            .ant-menu-title-content {
                color: #f5f5f5;
            }

            & > span > a,
            .anticon {
                color: #f5f5f5;
            }
        }

        .ant-menu-item {
            &::after {
                border-right: 3px solid #1890ff;
            }
        }

        .ant-menu-item-selected {
            color: #1890ff;

            & > span > a {
                color: #1890ff !important;
            }

            .anticon {
                color: #1890ff;
            }
        }

        .ant-menu-item-selected {
            background-color: #e6f7ff;
        }
    }
}

.ant-table-cell {
    height: 56px;
    word-break: break-all;
}

.ant-modal-wrap {
    z-index: 1040;
}

.btn-link {
    color: $button-primary-color;
    border-bottom-color: $button-primary-color !important;
}

.required-mark:before {
    content: '* ';
    color: $red-3;
}

.text-right {
    text-align: right !important;
}

.color-icon-red-5 {
    color: #ff4d4f;
}

.table-overflow th, .table-overflow td {
    white-space: nowrap;
}

@media all and (max-width: 480px) {
    @import 'mobile/base_layout';
    @import 'mobile/accounts';
    @import 'mobile/diagnosticians';
    @import 'mobile/emails';
    @import 'mobile/login';
    @import 'mobile/trash';
    @import 'mobile/master_data';
    @import 'mobile/diagnostician_detail';
    @import 'mobile/account_detail';
    @import 'mobile/profiles';
    @import 'mobile/interview';
    @import 'mobile/loan';
    @import 'mobile/subsidy';
    @import 'mobile/business-management.scss';
    @import 'mobile/grant';
    @import 'mobile/notification-content-management';
    @import 'mobile/notification-management';
    @import 'mobile/company-management';
    @import 'mobile/master_data_subsidy';
    @import 'mobile/master_data_grant';
    @import 'mobile/loan_detail';
    @import 'mobile/subsidy-detail';
    @import 'mobile/grant-detail';
    @import 'mobile/subsidy_addition';
}
