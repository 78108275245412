.login-form-container {
    .login {
        width:95vw;
        padding: 50px 20px;
    }
}

.login-form-container {
    position: inherit;
    height: inherit;
    margin: 100px auto;
    padding: 0 10px;
    width: 100%;
}
.labor_social_navbar {
    padding: 5px 25px;

    .title {
        font-size: 16px;
    }
}
