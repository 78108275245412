@import '../variables';

.company-management {
    height: 100%;

    .header-container {
        display: flex;
        margin-top: 30px;
        justify-content: space-between;
    }

    .delete-btn {
        background-color: #ff4d4f;
        color: white;
    }

    .action-btn {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        width: 88px;
    }

    .title {
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        color: #444444;
        margin-bottom: 12px !important;
    }

    .ant-tabs-tab-btn {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
    }

    .ant-tabs-tab-active .ant-tabs-tab-btn {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
    }

    .action-buttons {
        margin-left: auto;

        .block-status {
            min-width: 175px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
        }

        .block-datepicker {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.85);
        }

        .block-search {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
        }

        button.filter-button {
            color: black;
            border: 1px solid $gray-6;
            padding: 3px 8px;
            background: white;
            max-width: 32px;
        }

        .block-search {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
        }

        .ant-input-search-button {
            border: 1px solid $gray-6 !important;
            background-color: white;
        }

        .ant-btn-dangerous {
            background: $red-1;
        }
    }

    .ant-list-item {
        display: block;
        padding: 5px 0;
    }

    .text {
        background: #e0e0e0;
    }

    .ant-list-split .ant-list-item {
        border-bottom: none;
    }

    .ant-collapse {
        border: 1px solid $gray-1;
    }

    .ant-collapse-item {
        border-bottom: $gray-1;
    }

    .ant-collapse .ant-collapse-item {
        background: $gray-1;
    }

    .ant-collapse-content {
        border-top: 1px solid $gray-1;
    }

    .ant-collapse-header {
        display: block;
    }

    .branch-sorter {
        margin-left: 10px;
        font-size: 14px;
        font-weight: bold;

        a {
            color: black;
        }
    }

    .total-record {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 10px;
        margin-top: 4px;
        color: #000000;
    }

    .file-name {
        align-items: center;
        display: flex;
    }

    .table-manage-file {
        padding-bottom: 50px;
    }
    //Make centered text to first row
    .first-row {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: 100%;
    }

    .custom-table {
        .action-button {
            float: none;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .status {
                width: 200px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 22px;
            }

            .ant-btn {
                min-width: 100px;

                &:not(:last-child) {
                    margin-right: 10px;
                }
            }

            .ant-pagination {
                justify-content: center !important;
                padding: 3px 0 27px;
            }

            .edit-button {
                border: 1px solid $button-secondary-outline;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
            }
        }

        .ant-table-cell {
            height: 56px;
        }

        .ant-table-thead > tr > th {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
        }

        .ant-table-tbody > tr > td {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
            border: none;
        }

        .ant-pagination {
            justify-content: center !important;
            padding: 3px 0 27px;
        }

        .ant-layout {
            height: 100% !important;
        }

        .ant-table {
            flex-grow: 1;
        }

        .ant-spin-container {
            display: flex;
            flex-direction: column;
        }
    }

    .company-info {
        .style-common {
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            font-size: 14px;
            color: #000000;
        }

        .style-content {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .update-content {
            width: 100%;
        }

        .end-date {
            font-weight: 700;
            font-size: 12px;
        }

        .title-info {
            font-size: 16px;
            font-weight: 700;
            padding-top: 18px;
        }

        .custom-table {
            .ant-spin-container {
                height: auto !important;
                display: flex;
                flex-direction: column;
            }

            .edit-button {
                border: 1px solid $button-secondary-outline;
                width: 86px;
                height: 32px;
            }

            .status {
                background-color: #fffbe6;
                border: 1px solid $button-secondary-outline;
                // color: #faad14;
                min-width: 85px;
                text-align: center;
                // border-color: #faad14;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 20px;
            }
        }

        .background-profile {
            background: #f5f5f5;
            padding: 25px 90px;
            margin-top: 17px;
            border-radius: 3px;
            justify-content: space-evenly;
            display: flex;

            .text-normal {
                font-size: 12px;
            }

            .text-bold {
                font-weight: 800;
                font-size: 12px;
            }

            .data-list {
                display: flex;
                justify-content: space-between;
                align-content: flex-start;
            }
        }

        .personal-info {
            margin-top: 50px;

            .label {
                font-weight: 700;
                padding-bottom: 4px;
            }

            .content {
                font-size: 13px;
            }

            .change-button {
                color: #ffffff;
                background-color: #006bb5;
                width: 74px;
            }
        }
    }
}

.exchange-detail {
    .style-common {
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
        font-size: 14px;
        color: #999999;
    }

    .style-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .exchange-card {
        width: 100%;
        margin-top: 17px;
    }

    .color-icon {
        color: #006bb5;
        font-size: 17px;
        font-weight: bold;
    }

    .exchange-title {
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        color: #444444;
    }

    .card-one {
        background-color: #fff8d4;
        border: 1px solid $button-secondary-outline;
        border-color: #efcb0d;
    }

    .card-two {
        background-color: #f5f5f5;
        border: 1px solid $button-secondary-outline;
        border-color: #e0e0e0;
    }

    .card-three {
        background-color: #e6f7ff;
        border: 1px solid $button-secondary-outline;
        border-color: #91d5ff;
    }

    .unconfirm {
        color: #e0c015;
    }

    .consultation {
        font-weight: 600;
        line-height: 18px;
        font-size: 12px;
    }
}

@media all and (max-width: 1024px) {
    .company-management {
        .company-info {
            .background-profile {
                padding: 25px 45px;
            }
        }
    }
}
