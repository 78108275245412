.landing-navbar {
    position: absolute;
    top: 0;
    padding: 19px 136px;
    display: flex;
    width: 100%;
    z-index: 100;

    .buttons {
        margin-left: auto;
        align-self: center;
    }

    .buttons button {
        height: 40px;
        min-width: 160px;
        background-color: #f2f2f2;
        color: #01a0dd;

        &:hover {
            background-color: #01a0dd;
            color: #f2f2f2;
        }
    }

    .logo {
        display: flex;
        flex-direction: column;

        img {
            height: 45px;
            width: max-content;
            align-self: center;
        }

        span {
            font-size: 12px;
            color: #fff;
            letter-spacing: 1px;
        }
    }
}
