@import '../variables';

.diagnosticians-management {
    .header-container {
        display: flex;
    }

    .title {
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        color: #444444;
        margin-bottom: 12px !important;
    }

    .ant-tabs-tab-btn {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
    }

    .ant-tabs-tab-active .ant-tabs-tab-btn {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
    }

    .action-buttons {
        margin-left: auto;

        .btn-create {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            min-width: 104px;
            background: $button-primary-color;
            color: $button-secondary-color;
        }

        button.filter-button {
            color: black;
            border: 1px solid $gray-6;
            padding: 3px 8px;
            background: white;
            max-width: 32px;
        }

        .block-search {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            min-width: 242px;
        }

        .ant-input-search-button {
            border: 1px solid $gray-6 !important;
            background-color: white;
        }

        .ant-btn-dangerous {
            background: $red-1;
        }
    }

    .ant-list-item {
        display: block;
        padding: 5px 0;
    }

    .ant-list-split .ant-list-item {
        border-bottom: none;
    }

    .ant-collapse {
        border: 1px solid $gray-1;
    }

    .ant-collapse-item {
        border-bottom: $gray-1;
    }

    .ant-collapse .ant-collapse-item {
        background: $gray-1;
    }

    .ant-collapse-content {
        border-top: 1px solid $gray-1;
    }

    .ant-collapse-header {
        display: block;
    }

    .branch-sorter {
        margin-left: 10px;
        font-size: 14px;
        font-weight: bold;

        a {
            color: black;
        }
    }

    .total-record {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 10px;
        margin-top: 4px;
        color: #000000;
    }

    .custom-table {
        .action-buttons {
            float: none;

            .ant-btn {
                min-width: 100px;

                &:not(:last-child) {
                    margin-right: 10px;
                }
            }

            .edit-button {
                border: 1px solid $button-secondary-outline;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 22px;
            }
        }

        .ant-table-cell {
            height: 56px;
        }

        .ant-table-thead > tr > th {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
        }

        .ant-table-tbody > tr > td {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
        }
    }
}
