body#work-regulation {
    section.header {
        position: relative;

        background: url('../images/main_header.png') center no-repeat;
        width: 100%;
        background-size: cover;
        background-position-y: 70%;

        img {
            width: 30%;
        }

        .content {
            height: 800px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: linear-gradient(180deg, rgba(1, 160, 221, 0.65) 55.21%, rgba(1, 160, 221, 0) 100%);
        }
    }

    section.sub-header {
        position: relative;
        background: url('../images/kisoku_sub_header.png') center no-repeat;
        width: 100%;
        background-size: cover;

        img {
            width: 30%;
        }

        .content {
            background: linear-gradient(180deg, rgba(1, 160, 221, 0.65) 55.21%, rgba(1, 160, 221, 0) 100%);
            background-size: cover;
            width: 100%;
            justify-content: center;
            align-items: center;
            display: flex;

            .title {
                margin-top: 10%;
                font-size: 36px;
                color: #ffffff;
            }
        }
    }

    section.static-content {
        padding: 20px 200px 100px 200px;

        .ant-card {
            background: #fff;
        }
    }

    footer.landing-footer {
        background-image: url('../images/kisoku_footer.png');
        color: #ffffff;

        .footer-wrapper {
            background: linear-gradient(0deg, rgba(1, 160, 221, 0.48), rgba(1, 160, 221, 0.48));
        }
    }

    section.primary-content {
        text-align: center;
        align-items: center;
        padding-top: 53px;
        padding-bottom: 53px;

        .content {
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
            margin-top: 15px;
            display: block;
        }
    }

    section.secondary-content {
        background: #f3faff;
        text-align: center;
        align-items: center;
        padding-top: 53px;
        padding-bottom: 53px;

        .content {
            color: #656565;
            font-size: 12px;
            font-weight: 500;
            margin-top: 8px;
            display: block;
        }
    }

    .text-justify {
        text-align: justify !important;
    }

    .title {
        color: #01a0dd;
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
        display: block;
    }

    .subtitle {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        margin-top: 15px;
        display: block;
    }

    .ant-card {
        background: #f2f2f2;
        margin-top: 10px;

        .topic-date-col {
            font-size: 8px;
            padding: 20px;

            .topic-date-container {
                background: #999999;
                color: #ffffff;
                padding: 2px;
            }
        }
    }

    .footer-items a {
        color: #ffffff !important;
    }
}
