@import '../variables';

.application-detail {
    .application {
        &-status-card {
            .status-date {
                text-align: right;
                margin-bottom: 10px;
            }

            .status-text {
                text-align: right;
                font-weight: 700;
            }

            .status-title, .status-date {
                color: black;
            }

            .status {
                &-matching {
                    background: $orange-1;
                    border-color: $orange-2;
                    color: $orange-3;
                }

                &-selecting-specialist {
                    background: $brown-1;
                    border-color: $brown-2;
                    color: $brown-3;
                }

                &-scheduled {
                    background: $light-blue-1;
                    border-color: $light-blue-2;
                    color: $button-primary-color;
                }

                &-finished {
                    background: $green-1;
                    border-color: $green-2;
                    color: $green-3;
                }

                &-no-match {
                    background: $purple-1;
                    border-color: $purple-2;
                    color: $purple-3;
                }

                &-canceled {
                    background: $red-1;
                    border-color: $red-2;
                    color: $red-3;
                }

                &-past-due {
                    background: $purple-1;
                    border-color: $purple-2;
                    color: $purple-3;
                }

                &-requesting {
                    background: $purple-1;
                    border-color: $purple-2;
                    color: $purple-3;
                }

                &-submitted {
                    background: $gray-1;
                    border-color: $gray-4;
                    color: $gray-4 !important;
                }

                &-waiting-conf {
                    background: $orange-1;
                    border-color: $orange-2;
                    color: $orange-3;
                }

                &.gray {
                    border-color: $gray-3;
                    background: $gray-2;
                }

                &.light-blue {
                    border-color: $light-blue-2;
                    background: $light-blue-1;
                }

                &.red {
                    border-color: $red-2;
                    background: $red-1;
                }
            }

            &-title {
                font-size: 14px;
                font-weight: bold;
            }

            .fund-info {
                background: $yellow-1;
                border-color: $yellow-1;
                padding: 10px 0;
            }
        }

        &-reason-cancel-card {
            .ant-card {
                background: $red-1;
                border: 1px solid $red-2;
                color: $gray-4;
            }
        }

        &-person-in-charge-card,
        &-schedule-data-card {
            .ant-card {
                background: $gray-2;
                border: 1px solid $gray-3;
                color: black;
                .datetime {
                    color: $green-3;
                }
            }
        }

        &-topic-card,
        &-remark-card {
            .ant-card {
                background: $light-blue-1;
                border: 1px solid $light-blue-2;
                color: $text-black;
            }
        }
    }

    .note-text {
        color: $red-3;
        margin-top: 10px;
    }

    .light-blue-4 {
        color: $light-blue-4;
    }

    .text-gray {
        color: $gray-4;
    }

    .ant-btn-copy-join-url {
        background-color: $light-blue-1;
        color: $button-primary-color;
        border-color: $button-primary-color;
    }
}
