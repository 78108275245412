.business-detail {
    .header-container {
        flex-direction: column;
    }

    .action-buttons {
        margin-left: 0;
        margin-bottom: 6px;
    }
}
