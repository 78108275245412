@import 'variables';

body#labor-social {
    background-image: url('../images/bg_login.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    
    .login-modal .ant-modal-close-x {
        display: none !important;
    }

    .ant-modal-mask {
        background-color: transparent !important;
    }

    .login-modal,
    .forgot-password-modal,
    .success-modal {
        margin-top: 135px !important;
    }

    .ant-layout {
        background: 0;
        color: $button-secondary-color;
    }

    .labor-social-content {
        margin: 135px 175px 100px;
        flex-direction: row;
        display: flex;
        justify-content: center;

        .register-button {
            margin-top: 60px;
            margin-left: 510px;
        }
    }
}
