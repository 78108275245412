.company-management {
    .title {
        margin-bottom: 10px;
    }

    .header-container {
        flex-direction: column;
        margin-bottom: 6px;
    }

    .action-buttons {
        margin-left: 0;
    }

    .company-info {
        .background-profile {
            padding: 25px 20px;
            .data-list {
                display: flex;
                justify-content: space-between;
                align-content: flex-start;
            }
        }
    }
}
