@import '../variables';

.react-resizable {
    position: relative;
    background-clip: padding-box;
}
  
.react-resizable-handle {
    position: absolute;
    right: -5px;
    bottom: 0;
    z-index: 1;
    width: 10px;
    height: 100%;
    cursor: col-resize;
}

.email-management {
    height: 100%;
    display: flex;
    flex-direction: column;

    .header-container {
        margin-top: 24px;
        display: flex;
    }

    .title {
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        color: #444444;
        margin-bottom: 17px !important;
    }

    .action-buttons {
        margin-left: auto;

        button.filter-button {
            color: black;
            border: 1px solid $gray-6;
            padding: 3px 10px;
            background: white;
        }

        .ant-input-search-button {
            border: 1px solid $gray-6 !important;
            background-color: white;
        }

        .ant-btn-dangerous {
            background: $red-1;
        }
    }

    .ant-list-item {
        display: block;
        padding: 5px 0;
    }

    .ant-list-split .ant-list-item {
        border-bottom: none;
    }

    .ant-collapse {
        border: 1px solid $gray-1;
    }

    .ant-collapse-item {
        border-bottom: $gray-1;
    }

    .ant-collapse .ant-collapse-item {
        background: $gray-1;
    }

    .ant-collapse-content {
        border-top: 1px solid $gray-1;
    }

    .ant-collapse-header {
        display: block;
    }

    .branch-sorter {
        margin-left: 10px;
        font-size: 14px;
        font-weight: bold;

        a {
            color: black;
        }
    }

    .total-record {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 10px;
        margin-top: 4px;
        color: #000000;
    }

    .custom-table {
        .action-buttons {
            float: none;

            .ant-btn {
                min-width: 100px;

                &:not(:last-child) {
                    margin-right: 10px;
                }
            }

            .edit-button {
                border: 1px solid $button-secondary-outline;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 22px;
                border-radius: 2px;
                color: $button-black-color;
            }
        }

        .ant-table-thead > tr > th {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            height: 56px;
        }

        .ant-table-tbody > tr > td {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
        }

        .ant-layout {
            height: 100% !important;
        }

        .ant-pagination {
            justify-content: center !important;
            padding: 3px 0 27px;
        }

        .ant-table {
            flex-grow: 1;
        }

        .ant-spin-container {
            display: flex;
            flex-direction: column;
        }
    }
}
